<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <h1 class="display-2">Privacy Policy</h1>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <p>
          This X-Team Ltd Privacy Policy applies to your use of Glitch Exp, and any other websites, applications, or services provided, owned, or operated by X-Team Ltd that link to this Privacy Policy. X-Team Ltd values the privacy of users, subscribers, publishers, members, and others who visit
          and use the X-Team Ltd Services (collectively or individually, “you” or “users”) and wants you to be familiar with how we collect, use, and disclose personal information from and about you.
        </p>
        <p>For purposes of data protection laws, X-Team Ltd., located at Rotunda Buildings, Montpellier Exchange, Cheltenham, Gloucestershire, England, GL50 1SX, is the “data controller” of your information.</p>
        <p>You are agreeing to this Privacy Policy by using the X-Team Ltd Services, you consent to the extent permitted by law to the information handling practices described in this Policy.</p>
        <p>Glitch Exp does not use <strong>Cookies</strong> and therefore does not have a cookie policy</p>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <h2 class="display-1">Personal Information X-Team Ltd Collects About You</h2>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <p>On login we collect your Twitch username, display name and user identifier. We collect subscriber events and bits use events on your channel while you are included in any Twitch marketing campaigns</p>
        <p><strong>MATOMO:</strong> Our Analytics software is Matomo, this runs in a cookieless mode. We are the storage and processing agent of this data, your data is not transferred out of our Matomo instance, your IP address is sent to our Analytics software however is not stored.</p>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <h2 class="display-1">Sharing of personal information as described below:</h2>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <p>The personal information we do collect (Twitch username, display name and user identifier) will be shared with Twitch Interactive Inc. ("Twitch"). As this site is powered by and used by Twitch.</p>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <h2 class="display-1">Data Subject Rights and Your Choices</h2>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <p>
          Residents of the European Economic Area (“EEA”), can exercise certain data subject rights available to them under applicable data protection laws. We will comply with requests to exercise these rights in accordance with applicable law. Please note that in some circumstances, we may need to
          keep processing your information for certain legitimate interests or to comply with a legal obligation. If these rights apply to you, they may permit you to request that we:
        </p>
        <ul>
          <li>Obtain access to or a copy of certain personal information we hold about you</li>
          <li>Prevent the processing of your personal information for direct marketing purposes (including any direct marketing processing based on profiling)</li>
          <li>Update personal information that is out of date or incorrect</li>
          <li>Delete certain personal information we hold about you</li>
          <li>Restrict the way that we process and disclose specific personal information about you</li>
          <li>Transfer your personal information to a third-party provider of services</li>
          <li>Revoke consent that you previously provided for the processing of your personal information</li>
        </ul>
        <p>
          Please email
          <a href="mailto:support@slevinthheaven.com">SUPPORT</a> for any help or support with regards to this Privacy Policy
        </p>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <h2 class="display-1">Account Closure &amp; Deletion</h2>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <p>
          If you would like to close your account and delete your profile information on Glitch Exp please email
          <a href="mailto:support@slevinthheaven.com">SUPPORT</a>. For Slevinth Heaven Ltd Services other than Glitch Exp, you may update or delete information tied to your account, or close an account, when you email support, give us information of all X-Team Ltd services you use
        </p>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <h2 class="display-1">Third-Party Services and Websites</h2>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <p>The X-Team Ltd Services may link to third-party websites or services. The privacy practices of those third parties are not governed by this Privacy Policy. We encourage you to review the privacy policies of these third-party websites and services to understand their practices.</p>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <h2 class="display-1">Advertisers and Analytics Providers</h2>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <p>We currently do not connect to or share information with Advertisers or Analytics Providers, this Privacy Policy will be changed to reflect this if/when that happens</p>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <h2 class="display-1">Do Not Track</h2>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <p>
          Do Not Track (“DNT”) is a privacy preference that users can set in certain web browsers. DNT is a way for users to inform websites and services that they do not want certain information about their webpage visits collected over time and across websites or online services. We are committed
          to providing you with meaningful choices about the information collected on our website for third-party purposes, and that is why we provide the Network Advertising Initiative, Your Online Choices and the Digital Advertising Alliance opt-out links above. However, X-Team Ltd does not
          recognize or respond to browser-initiated DNT signals.
        </p>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <h2 class="display-1">Data Security</h2>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <p>
          X-Team Ltd uses a variety of managerial, technical, and physical measures to protect the integrity and security of your information. These measures may vary based on the sensitivity of your information. However, no security precautions or systems can be completely secure. We cannot ensure
          or warrant the security of any information you transmit to X-Team Ltd, and you do so at your own risk. We cannot guarantee that such information may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards.
        </p>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <h2 class="display-1">Retention of Your Information</h2>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <p>
          We keep your personal information to enable your continued use of X-Team Ltd Services, for as long as it is required in order to fulfill the relevant purposes described in this Privacy Policy, as may be required (or permitted) by law such as for tax and accounting purposes, or as otherwise
          communicated to you.
        </p>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <h2 class="display-1">International Data Transfers</h2>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <p>
          Information collected by X-Team Ltd may be stored and processed in your region, in the United States (for instance in our major data centers), or in any other country where X-Team Ltd or its affiliates, subsidiaries, partners, or service providers are located or maintain facilities. If we
          provide any information about you to any such entities, we will take appropriate measures to ensure such companies protect your information adequately in accordance with this Privacy Policy and applicable law.
        </p>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <h2 class="display-1">Changes and Updates to this Privacy Policy</h2>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <p>
          X-Team Ltd reserves the right to change, modify, add, or remove portions of this Privacy Policy at any time (for example to reflect updates to the X-Team Ltd Services or to reflect changes in the law). Please check this Privacy Policy periodically for those changes. Your continued use of
          the X-Team Ltd Services after the posting of changes constitutes your binding acceptance of such changes.
        </p>
        <p>We will not materially change our policies and practices to make them less protective of personal information collected in the past under a prior version of the Privacy Policy without the consent of affected individuals</p>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <h2 class="display-1">X-Team Ltd Contact Information</h2>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <p>
          Please contact X-Team Ltd with any questions or comments about this Privacy Policy by email to
          <a href="mailto:support@slevinthheaven.com">support@slevinthheaven.com</a>. We will respond to your inquiry within 30 days of its receipt.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
}
</script>
