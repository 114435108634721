<template>
  <v-app>
    <div class="home">
      <div class="bg"></div>
      <div class="bg-shape"></div>
      <div class="content">
        <template v-if="isLoginEnabled">
          <div class="login-bg"></div>
          <div class="login" @click="login">{{ $vuetify.lang.t('$vuetify.login') }} ↗</div>
        </template>
        <div>
          <div>
            <v-combobox :items="languages" v-model="language" class="language-select" solo v-on:change="changeLocale" prepend-inner-icon="mdi-web" append-icon="" color="black"></v-combobox>
          </div>
        </div>
        <div class="welcome">{{ $vuetify.lang.t('$vuetify.heading_welcome') }}</div>
        <img class="twitch-purple-heart" src="/imgs/twitch-purple-heart.png" />
        <a href="https://www.twitch.tv" target="_blank"><img class="twitch-logo-header" src="/imgs/twitch-logo.png" /></a>
        <div class="welcome-section">
          <span>{{ $vuetify.lang.t('$vuetify.home_para1') }}</span>
          <span class="welcome-rewards">{{ $vuetify.lang.t('$vuetify.rewards') }}</span>
          <span>{{ $vuetify.lang.t('$vuetify.home_para1_1') }}</span>
          <span>
            <span>
              {{ $vuetify.lang.t('$vuetify.home_para1_2') }}<a @click="policy = true">{{ $vuetify.lang.t('$vuetify.privacy_policy') }}</a
              ><span v-html="$vuetify.lang.t('$vuetify.home_para1_3')"></span>
            </span>
            <br />
            <span></span>
          </span>
        </div>
        <div class="reminder-section">
          <div class="participating" v-html="$vuetify.lang.t('$vuetify.home_para2')"></div>
          <div class="reminder">
            <img class="reminder-logo" src="/imgs/reminder.png" />
            <div>{{ $vuetify.lang.t('$vuetify.reminder') }}</div>
          </div>
        </div>
        <img class="red-bits-logo-mobile" src="/imgs/red-bits-mobile.png" />
        <img class="red-bits-logo" src="/imgs/red-bits.png" />
        <div class="footer">
          <a href="https://www.twitch.tv" target="_blank"><img class="twitch-glitch-white" src="/imgs/twitch-glitch-white.png" /></a>
          <div>
            <a class="terms-of-service" href="https://www.twitch.tv/p/en/legal/terms-of-service/" target="_blank">{{ $vuetify.lang.t('$vuetify.terms') }}</a>
          </div>
          <div class="partner">{{ $vuetify.lang.t('$vuetify.develop_by') }}</div>
          <div>
            <a class="privacy-policy" href="https://www.twitch.tv/p/en/legal/privacy-notice/" target="_blank">{{ $vuetify.lang.t('$vuetify.privacy_policy') }}</a>
          </div>
          <div class="trade-mark">© {{ new Date().getFullYear() }} Twitch Interactive Inc</div>
        </div>
      </div>
    </div>
    <v-dialog :value="policy" opacity="1" @click:outside="policy = false">
      <v-card flat>
        <v-card-text>
          <privacy-policy></privacy-policy>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="policy = false">{{ $vuetify.lang.t('$vuetify.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
import PrivacyPolicy from '../components/PrivacyPolicy.vue'

export default {
  components: {
    PrivacyPolicy
  },
  name: 'Home',
  data() {
    return {
      policy: false,
      language: 'English',
      // languages: ['EN', 'PT', 'FR', 'ES', 'DE', 'JA', 'ZH'] // English, Portuguese, French, Spanish, German, Japanese, Chinese
      languages: ['English', 'Português', 'Français', 'Español', 'Deutsch', '日本語', '中文 繁體'] // English, Portuguese, French, Spanish, German, Japanese, Chinese
    }
  },
  computed: {
    isLoginEnabled() {
      return process.env.VUE_APP_NO_PROD_REDIRECT === 'true'
    }
  },
  methods: {
    ...mapActions(['signin']),
    login() {
      sessionStorage.login = 'default'
      this.signin()
    },
    changeLocale() {
      let current
      switch (this.language) {
        case 'Português':
          current = 'pt'
          break
        case 'Français':
          current = 'fr'
          break
        case 'Español':
          current = 'es'
          break
        case 'Deutsch':
          current = 'de'
          break
        case '日本語':
          current = 'ja'
          break
        case '中文 繁體':
          current = 'zh'
          break
        default:
          current = 'en'
          break
      }
      this.$vuetify.lang.current = current
      localStorage.language = current
    }
  },
  mounted() {
    if (process.env.VUE_APP_NO_PROD_REDIRECT !== 'true') {
      location.replace('https://glitch-exp.com')
    }
    const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language
    const language = userLocale ?? localStorage?.language ?? 'en'
    let preferredLanguage
    if (language.includes('pt')) {
      preferredLanguage = 'pt'
      this.language = 'Português'
    } else if (language.includes('fr')) {
      preferredLanguage = 'fr'
      this.language = 'Français'
    } else if (language.includes('es')) {
      preferredLanguage = 'es'
      this.language = 'Español'
    } else if (language.includes('de')) {
      preferredLanguage = 'de'
      this.language = 'Deutsch'
    } else if (language.includes('ja')) {
      preferredLanguage = 'ja'
      this.language = '日本語'
    } else if (language.includes('zh')) {
      preferredLanguage = 'zh'
      this.language = '中文 繁體'
    } else {
      preferredLanguage = 'en'
      this.language = 'English'
    }
    this.$vuetify.lang.current = preferredLanguage
    localStorage.language = preferredLanguage
  }
}
</script>

<style lang="scss" scoped>
.home,
.home * {
  box-sizing: border-box;
}

.home {
  background: #000000 !important;
  width: 100%;
  height: 2475px;
  position: relative;
  overflow: hidden;

  @media only screen and (max-height: 900px) {
    height: 1000px;
  }
}

.bg {
  background: linear-gradient(180deg, rgba(30, 105, 255, 1) 0%, rgba(0, 250, 250, 1) 55.476218461990356%, rgba(30, 150, 255, 1) 75.32358169555664%);
  width: 100%;
  height: 1604px;
  position: absolute;
  left: 0px;
  top: 0px;

  @media only screen and (max-height: 900px) {
    height: 430px;
  }
}

.bg-shape {
  background: #000000;
  border-radius: 100px 100px 0px 0px;
  width: 100%;
  height: 1320px;
  position: absolute;
  left: 0px;
  top: 736px;

  @media only screen and (max-height: 900px) {
    top: 760px;
    top: 226px;
    height: 759px;
    left: 0px;
    top: 232px;

    background: #000000;
    border-radius: 30px 30px 0px 0px;
  }
}

.content {
  margin: auto;
  padding: 12px;
  min-width: 1420px;
  max-width: 1920px;

  @media only screen and (max-height: 900px) {
    max-height: 900px;
  }
}

.login-bg {
  background: #9146ff;
  border-radius: 45px;
  width: 130px;
  height: 37.53px;
  position: absolute;
  top: 72px;
  right: 100px;

  @media only screen and (max-height: 900px) {
    top: 56px;
    right: 20px;
    width: 81px;
    height: 26px;
  }
}

.login {
  z-index: 1000;
  color: #000000;

  font-family: 'RoobertRegular';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  /* or 90% */
  letter-spacing: -0.005em;

  position: absolute;
  top: 70px;
  right: 104px;
  width: 120px;
  height: 38.59px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media only screen and (max-height: 900px) {
    font-size: 12px;
    top: 50px;
    right: 0px;
  }
}

.hidden {
  display: none;
}

.language-select {
  border-radius: 20px;
  cursor: pointer;
  z-index: 100;
  font: 600 20px 'RoobertRegular', sans-serif;
  position: absolute;
  top: 65px;
  right: 180px;
  width: 115px;

  @media only screen and (max-height: 900px) {
    font-size: 12px;
    top: 46px;
    right: 96px;
    width: 48px;
    z-index: 1001;
  }
}

.twitch-logo-header {
  z-index: 1;
  width: 131px;
  height: 44px;
  position: absolute;
  left: 100px;
  top: 60px;

  @media only screen and (max-height: 900px) {
    width: 54px;
    height: 18px;
    left: 18px;
  }
}

.twitch-purple-heart {
  width: 550.29px;
  height: 847.29px;
  position: absolute;
  top: 23px;
  right: -12px;

  @media only screen and (max-height: 900px) {
    width: 128px;
    height: 208px;
    top: 104px;
    right: -22px;
  }
}

.welcome {
  color: #000000;
  text-align: left;
  font-family: 'RoobertRegular';
  font-style: normal;
  font-weight: 700;
  font-size: 225px;
  line-height: 90%;
  letter-spacing: -0.03em;
  position: relative;
  margin: auto;
  top: 168px;
  left: -150px;
  max-width: 1084px;
  height: 491px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media only screen and (max-height: 900px) {
    z-index: 1;
    position: absolute;
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 90%;
    letter-spacing: -0.03em;
    top: -90px;
    left: 40px;
    width: 300px;
  }
}

.welcome-section {
  z-index: 1;
  top: 341px;
  color: rgba(240, 240, 255, 1);
  margin: auto;
  max-width: 1350px;
  height: auto;
  position: relative;
  font-size: 45px;
  font-style: SemiBold;
  text-align: left;
  font-weight: 600;
  line-height: 125%;
  font-stretch: normal;
  text-decoration: none;

  @media only screen and (max-height: 900px) {
    position: absolute;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 125%;
    min-width: 325px;
    height: 306px;
    left: 40px;
    top: 260px;
    right: 50px;
  }
}

.welcome-rewards {
  color: rgba(190, 255, 0, 1);
}

.policy {
  cursor: pointer;
}

.reminder-section {
  margin: auto;
  max-width: 1350px;
  position: relative;

  @media only screen and (max-height: 900px) {
    position: absolute;
  }
}

.participating {
  font: 600 45px/125% 'RoobertRegular', sans-serif;
  margin-top: 600px;
  margin-left: 310px;
  width: 732px;
  background: linear-gradient(180deg, #eaffac 0%, #beff00 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media only screen and (max-height: 900px) {
    width: 100%;
    min-width: 325px;
    margin-top: 610px;
    margin-left: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 125%;
    padding-right: 75px;
  }
}

.reminder {
  display: flex;
  margin-top: -700px;
  color: #f0f0ff;
  font: 600 55px/64.44px 'RoobertRegular', sans-serif;

  @media only screen and (max-height: 900px) {
    margin-top: -265px;
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 64px;
  }
}

.reminder-logo {
  width: 56px;
  height: 56px;
  margin-left: 310px;
  margin-right: 20px;
  margin-top: 4px;

  @media only screen and (max-height: 900px) {
    width: 21px;
    height: 21px;
    margin-left: 30px;
    margin-right: 10px;
    margin-top: 20px;
  }
}

.red-bits-logo {
  width: 695.91px;
  height: 1000px;
  position: absolute;
  left: 0px;
  top: 1357px;

  @media only screen and (max-height: 900px) {
    visibility: hidden;
  }
}

.red-bits-logo-mobile {
  visibility: hidden;

  @media only screen and (max-height: 900px) {
    visibility: visible;
    position: absolute;
    top: 428px;
    right: 0px;
  }
}

.footer {
  position: absolute;
  inset: 0;
}

.twitch-glitch-white {
  z-index: 1;
  width: 64px;
  height: 75px;
  position: absolute;
  left: 98px;
  top: 2192px;

  @media only screen and (max-height: 900px) {
    width: 20px;
    height: 24px;
    left: 20px;
    top: 860px;
  }
}

.terms-of-service {
  color: #ffffff;
  text-align: center;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 100px;
  top: 2349px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  @media only screen and (max-height: 900px) {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 37px;
    left: 20px;
    top: 900px;
  }
}

.partner {
  color: #b3b3b3;
  text-align: right;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  top: 2349px;
  right: 98px;
  width: 984px;
  align-items: right;

  @media only screen and (max-height: 900px) {
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 14px;
    right: 20px;
    top: 937px;
    min-width: 200px;
    max-width: 220px;
  }
}

.privacy-policy {
  color: #ffffff;
  text-align: center;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 330px;
  top: 2349px;
  display: flex;
  justify-content: left;
  text-decoration: none;

  @media only screen and (max-height: 900px) {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 37px;
    left: 20px;
    top: 924px;
  }
}

.trade-mark {
  color: #ffffff;
  text-align: center;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  top: 2316px;
  right: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-height: 900px) {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 37px;
    right: 20px;
    top: 900px;
  }
}
</style>
